import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`User page`}</h4>
    <p>{`When you are logged in you can click on your profile picture in the top
right corner to open the main menu.`}</p>
    <br />
    <p>{`In the main menu, click on "My page" to open your user page.`}</p>
    <br />
    <p>{`In your user page you can see an overview over all your uploaded tracks
(public, hidden, and secret), your reviews, and condition reports.`}</p>
    <Image src="features/userpagetrails.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <h4>{`Dashboard`}</h4>
    <p>{`In the dashboard you can select a period in the drop down menu and see how
often your trails were viewed, downloaded, reviewed, or had conditions reported.`}</p>
    <Image src="features/userpagedashboard.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/login" mdxType="Link">
  ← Login
    </Link>
    <Link className="float-right" to="/features/showreviews" mdxType="Link">
  Show reviews →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      